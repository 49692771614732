@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Rochester&display=swap");

/* for header */

/* to hide the arrows of input type "number" */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

header {
  position: fixed;
  width: 100%;
  height: 73px;
  background-color: #fff;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid #cccccc;
}

.sub-heading {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #212529;
}

header .container-fluid {
  width: 92%;
}
.navbar {
  display: flex;
  justify-content: space-between;
  padding: 8px 70px;
}
.navbar img {
  width: 89.15px;
  height: 55.5px;
}
.navbar .right-part span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  top: 24px;
  position: absolute;
  margin-left: 15px;
}
/* .navbar .left-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */
.navbar .left-part h4 a {
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-right: 22px;
  color: #000;
  text-transform: capitalize;
}
.navbar .left-part h4 a:hover {
  color: #ffa500;
}
.success-message {
  font-size: 15px;
  margin: 15px 15px 0;
  background: #378e3a;
  padding: 6px 10px;
  border-radius: 10px;
  color: #fff;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 40px;
}
.navbar .left-part .get-started a {
  text-decoration: none;
}

.navbar .left-part .get-started a:hover {
  background-color: #cf9d06ec;
}

.hide-lg {
  display: none;
}

/* for banner */

.only-small .anchor0 {
  background-color: #54a937;
}

.only-small {
  margin-top: -5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.anchor {
  width: max-content;
  transition: all;
}

.banner {
  text-align: center;
  margin-top: 73px;
  height: 575px;
  background: linear-gradient(180deg, #0e96e2 0%, #11a7ea 100%);
}
.banner .banner-content {
  padding-bottom: 30px;
}
.banner .banner-content h1 {
  content: "An easy taxi & private hire website builder";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 72px;
  color: #fff;
  /* padding: 45px 150px 30px 150px; */
  padding-top: 75px;
  text-align: center;
  text-transform: capitalize;
}
.banner .banner-content p {
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  color: #101010;
  text-align: center;
  text-transform: capitalize;
  margin-top: -15px;
}

.banner .banner-content a {
  padding: 16px 33px 17px 32px;
  background: #ebb100;
  /* background: white; */
  /* color: #ec6408; */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  border: none;
  text-transform: uppercase;
  text-decoration: none;
}

.banner-card .single-banner-card {
  height: 160px;
  background-color: white;
  position: relative;
  border-radius: 20px;
  bottom: 145px;
  margin-bottom: -145px;
}
.banner-card .col-md-3:nth-child(1) .single-banner-card:after {
  content: url("./assets/image/g1.png");
  display: block;
  bottom: 70%;
  position: absolute;
  background: #ffff;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  left: 31%;
}
.banner-card .col-md-3:nth-child(2) .single-banner-card:after {
  content: url("./assets/image/g2.png");
  display: block;
  bottom: 70%;
  position: absolute;
  background: #ffff;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  left: 31%;
}
.banner-card .col-md-3:nth-child(3) .single-banner-card:after {
  content: url("./assets/image/g3.png");
  display: block;
  bottom: 70%;
  position: absolute;
  background: #ffff;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  left: 31%;
}
.banner-card .col-md-3:nth-child(4) .single-banner-card:after {
  content: url("./assets/image/g4.png");
  display: block;
  bottom: 70%;
  position: absolute;
  background: #ffff;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  left: 31%;
}
.banner-card p {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  padding: 59px 25px;
  text-align: center;
  text-transform: capitalize;
}
.service-info {
  position: relative;
}
.service-info span {
  color: black;
  left: 50px;
  z-index: -1;
  top: -100px;
  opacity: 0.7;
  position: absolute;
  font-size: 120px;
  font-weight: 600;
}
/* services */
.services {
  padding: 60px 10px;
}
.hav-bg a,
.hav-bg a:hover {
  color: #ffd554;
}
.hav-bg {
  /* background-color: #887bec; */
  background-color: #f2f6ff;
  padding: 20px 0;
  font-size: 30px !important;
  color: #fff !important;
  text-align: center !important;
  border: 1px solid #ccdaf9;
}
.hav-bg .get-started,
.hav-bg .get-started:hover {
  background-color: #54a937;
  text-decoration: none;
  font-weight: 500;
  text-transform: capitalize;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 21px;
  color: #fff;
}
.hav-bg img {
  width: 140px !important;
  display: block !important;
  height: auto !important;
  margin: 0 auto;
  margin-bottom: 40px;
}
.arrow {
  position: relative;
}
.arrow svg {
  width: 210px;
  height: 150px;
  position: absolute;
  left: 63%;
  transform: rotate(45deg);
  top: -70px;
}
.services .row {
  align-items: center;
}
.services h2 {
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 54px;
  line-height: 78px;
  text-transform: uppercase;
}
.services img {
  width: 100%;
  height: 450px;
  object-fit: contain;
}
.services .row:nth-child(even) {
  flex-direction: row-reverse;
  margin: 60px 0;
}
.services .row:nth-child(even) h2 {
  margin-left: 60px;
}

/* -----------for contact--------- */
.contact {
  background: #1d1d1d;
  padding: 75px 45px;
  margin: 20px 0 0 0;
  color: white;
}

.contact-left {
  text-align: left;
}

.contact-left .p1 {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #ec6408;
}

.contact-left .h2 {
  font-size: 40px;
  font-weight: 700;
}

.contact-left .p2 {
  font-weight: 500;
}
.contact-left .p3 {
  font-weight: 500;
}

.contact .col-md-4 {
  display: flex;
}
.contact .contact-image {
  margin-right: 20px;
}
.contact .contact-image img {
  color: #212326;
}
.contact .contact-info {
  margin-top: -14px;
}
.contact .contact-info h5 {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 52px;
  margin-bottom: 0;
  color: #000;
}
.contact .contact-info a {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 52px;
  color: #212326;
  text-decoration: none;
}

/* faq */
.faq {
  padding: 70px 0;
  background-color: #f0efec;
}
.contact-form {
  padding: 70px 0;
}
.contact h3 {
  text-align: center;
  font-style: normal;
  font-weight: 900;
  font-size: 33px;
  line-height: 49px;
  margin-bottom: 40px;
}
.contact {
  text-align: center;
}
.contact a:hover {
  border: 2px solid #242424;
  transition: ease 0.3s all;
  color: #242424;
}
.contact a {
  text-decoration: none;
  color: #242424;
  background: #fff;
  font-weight: 600;
  font-size: 18px;
  border-radius: 30px;
  padding: 14px 45px;
  border: 2px solid #fff;
  transition: ease 0.3s all;
}
.contact-form h3,
.services h3,
.faq h3 {
  text-align: center;
  font-style: normal;
  font-weight: 900;
  font-size: 33px;
  line-height: 49px;
  margin-bottom: 40px;
}
.faq .antd-collapse {
  background-color: #f0efec;
  border: none;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;
  margin: 0 120px;
}
:where(.css-dev-only-do-not-override-ph9edi).ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow
  svg {
  display: none;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #212326;
}
:where(.css-dev-only-do-not-override-ph9edi).ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 16px 30px;
  background-color: #f0efec;
}
.ant-collapse-header-text {
  font-weight: 800 !important;
}
/* for footer */
footer {
  padding-top: 17px;
  text-align: center;
  background-color: #1d1d1d;
  display: flex;
  align-items: center;
}
footer .left-part p {
  color: white;
  margin-bottom: 0;
}
footer .middle-part a {
  text-decoration: none;
  color: #fff;
  margin-right: 20px;
}
footer .right-part ul {
  list-style: none;
  display: flex;
}
footer .right-part ul li {
  margin-left: 20px;
}
footer .middle-part {
  width: 120%;
}
footer .col-md-5 {
  justify-content: center;
  display: flex;
}

/* privacy-policy */
.privacy-policy {
  margin-top: 73px;
  padding: 40px 0;
  background: #f0efec;
}
.privacy-policy h1 {
  text-align: center;
  font-style: normal;
  font-weight: 900;
  font-size: 45px;
  line-height: 49px;
  margin-bottom: 40px;
  text-transform: uppercase;
}

/* terms-service */
.terms-service {
  margin-top: 73px;
  padding: 40px 0;
  background: #f0efec;
}
.terms-service h1 {
  text-align: center;
  font-style: normal;
  font-weight: 900;
  font-size: 45px;
  line-height: 49px;
  margin-bottom: 40px;
  text-transform: uppercase;
}
.drop-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;
}
/* cookies-policy */
.cookies-policy {
  margin-top: 73px;
  padding: 40px 0;
  background: #f0efec;
}
.cookies-policy h1 {
  text-align: center;
  font-style: normal;
  font-weight: 900;
  font-size: 45px;
  line-height: 49px;
  margin-bottom: 40px;
  text-transform: uppercase;
}
.contact-form .single-banner-card {
  width: 40%;
  margin: 0 auto;
}
.contact-form .single-banner-card input {
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
  border-color: #919191;
  background: #fafafa;
}
.contact-form .single-banner-card textarea {
  border-radius: 0;
  border-radius: 5px;
  border-color: #919191;
  background: #fafafa;
}
input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus,
select:focus,
.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}
.contact-form .single-banner-card .btn-main,
.contact-form .single-banner-card .btn-main:hover,
.contact-form .single-banner-card .btn-main:focus {
  padding: 12px;
  background: #ebb100;
  text-transform: uppercase;
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  width: 100%;
  height: 50px;
  border-radius: 30px;
  display: block;
  text-decoration: none;
  color: #000;
  text-align: center;
}
img.baddge {
  width: 200px;
  position: absolute;
  top: -40px;
}
/* responsive */
@media (max-width: 1303px) {
  footer .col-md-4 {
    width: 50%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  footer .col-md-3 {
    width: 50%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  footer .col-md-5 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  footer .right-part ul {
    padding: 0;
  }
}

@media (max-width: 1200px) {
  .banner-card p {
    font-size: 17px;
  }
  .contact .contact-image img {
    width: 60px;
    height: 60px;
  }
  .contact .contact-info {
    margin-top: -17px;
  }
  .contact .contact-info h5 {
    margin-bottom: -15px;
    font-size: 18px;
  }
  .contact .contact-info a {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .contact-form .single-banner-card {
    width: 80%;
  }
  .banner-card .single-banner-card {
    height: 160px;
    background-color: white;
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    bottom: 145px;
    margin-bottom: -145px;
  }
  .banner-card .col-md-3:nth-child(1) .single-banner-card:after {
    bottom: 75%;
    padding: 13px 13px 9px 12px;
    left: 25%;
  }
  .banner-card .col-md-3:nth-child(2) .single-banner-card:after {
    bottom: 75%;
    padding: 13px 13px 9px 12px;
    left: 25%;
  }
  .banner-card .col-md-3:nth-child(3) .single-banner-card:after {
    bottom: 75%;
    padding: 17px 16px 13px 16px;
    left: 25%;
  }
  .banner-card .col-md-3:nth-child(4) .single-banner-card:after {
    bottom: 75%;
    padding: 20px 11px 10px 11px;
    left: 25%;
  }

  .banner-card p {
    padding: 40px 10px;
    font-size: 15px;
  }
  .services h2 {
    font-size: 48px;
    line-height: 64px;
  }
  .contact {
    margin-bottom: -40px;
  }
  .contact .row .col-md-4 {
    width: 100%;
    display: flex;
    margin-bottom: 30px;
    padding-left: 34px;
  }
  .faq .antd-collapse {
    margin: 0px;
  }
}

@media (max-width: 768px) {
  /* header .get-started:last-child{
  display: none;
 } */
  .arrow svg {
    display: none;
  }
  .service-info span {
    left: 65px;
    top: -40px;
    font-size: 70px;
  }
  .banner .banner-content h1 {
    font-size: 28px;
    line-height: 36px;
    padding: 30px 20px;
  }
  .banner-card {
    margin-top: 60px;
  }
  .banner-card .row .col-md-3 {
    width: 50%;
  }
  .banner-card .single-banner-card {
    height: 160px;
    background-color: white;
    position: relative;
    border-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    bottom: 0 !important;
    margin-bottom: 80px !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  }
  .banner-card .col-md-3 .single-banner-card:after {
    left: 33% !important;
  }
  .banner-card .single-banner-card p {
    padding: 57px 10px;
  }
  .services h3 {
    padding: 20px 20px 20px;
  }
  .services {
    padding: 20px 30px;
    margin-top: 30px;
  }
  .services h2 {
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    margin-bottom: 20px;
  }
  .services .row:nth-child(even) h2 {
    margin-left: 0 !important;
  }
  footer .row {
    flex-direction: column-reverse;
  }
  footer .col-md-4 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  footer .col-md-3 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  footer .right-part ul {
    padding: 0;
  }
}

.pricing-table .item {
  color: #000;
  padding: 23px;
  width: full;
  text-align: center;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.035);
  border: 1px solid #cccccc;
  min-height: 1450px;
  overflow: hidden;
}

.pricing-table h4 {
  font-size: 20px;
  font-weight: 400;
  padding: 8px 0;
}
.pricing-table .btn {
  background: #ed640a;
  border: none;
  width: 100%;
  padding: 15px 30px;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  margin-top: 20px;
}
.centered-modal .close-modal {
  position: absolute;
  right: 12px;
  top: 4px;
  text-decoration: none;
  color: #ff0000bf;
}
.pricing-table .btn:hover,
.pricing-table .btn:focus {
  background: #ff812e !important;
}
.pricing-table .price h4 {
  font-size: 20px;
  font-weight: 800;
  color: #e6610b;
}
.pricing-table .heading {
  height: 120px;
}
.pricing-table .features {
  /* min-height: 320px; */
}

.pricing-table .features h4 {
  text-align: left;
  /* color: #212529; */
  color: #606168;
}

.pricing-table .heading h3 {
  color: #212529;
}

.pricing-table .relative {
  position: relative;
}

.absolute {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.footer-btn {
  /* width: 90%; */
  margin: 0 23px;
}

/* responsive design for subscription plan */
@media (width > 992px) {
  .footer-btn {
    /* width: 85%; */
    /* margin: auto; */
    margin-top: 100px;
  }
  /* 
  .flex {
    background-color: #02acff;
    display: flex;
  } */
}

.absolute-ribbon {
  position: absolute;
  top: 20px;
  right: -60px;
  font-size: 15px;
  font-weight: 700;
  background-color: green;
  color: white;
  padding: 8px 20px;
  rotate: 45deg;
  width: 200px;
}

@media (min-width: 769px) and (max-width: 992px) {
  .heading .c-h3 {
    margin-left: -32px;
  }
}

@media (max-width: 576px) {
  .right-part span {
    display: none;
  }
  #products .content {
    width: 100% !important;
    margin: 0 auto;
    padding: 0 !important;
  }
  .navbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .right-part {
    width: 30%;
    text-align: left;
  }
  .left-part {
    width: 70%;
  }
  img.baddge {
    width: 110px !important;
    top: 0;
  }
  .banner-card .single-banner-card {
    height: 160px;
    background-color: white;
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    bottom: 145px;
    margin-bottom: -145px;
  }
  .services h2 {
    font-size: 38px;
    line-height: 44px;
    text-align: center;
  }
  .faq {
    padding: 70px 45px;
    background-color: #f0efec;
    text-align: center;
  }
  .faq h1 {
    font-size: 27px;
  }
  .services {
    padding: 0px 35px;
  }
  .privacy-policy h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 49px;
    margin-bottom: 20px;
  }
  .terms-service h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 49px;
    margin-bottom: 20px;
  }
  .cookies-policy h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 49px;
    margin-bottom: 20px;
  }
  .services img {
    height: auto;
  }
}
.pricing-table {
  margin-top: 40px !important;
}
#products .content {
  width: 70%;
  margin: 0 auto;
}

.hidden-field {
  display: none;
}

@media (max-width: 480px) {
  .contact .row .col-md-4 {
    margin-left: 0;
  }
  .contact {
    padding: 75px 20px;
  }
  .faq h1 {
    font-size: 24px;
  }
  .banner-card .row {
    justify-content: center;
  }
  .banner-card .row .col-md-3 {
    width: 95%;
  }
  /* header .navbar .left-part .get-started a {
    display: none;
  } */
  .hide-lg {
    display: block;
    bottom: 0;
    z-index: 1;
    position: fixed;
    width: 100%;
  }
  .hide-lg a {
    padding: 16px 20px 32px 20px;
    text-transform: uppercase;
    border: 1px solid #fff;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    height: 46px;
    background: #ebb100;
    text-decoration: none;
    color: #000;
    width: 100%;
    display: block;
    text-align: center;
  }

  footer {
    margin-bottom: 49px;
  }
}

.content {
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  padding: 0 30px;
  margin: 10px 0;
}

@media (max-width: 380px) {
  .contact .contact-image img {
    width: 46px;
    height: 46px;
  }
  .contact .contact-info h5 {
    margin-bottom: -21px;
    font-size: 15px;
  }
  .contact .contact-info a {
    font-size: 16px;
  }
  .services h2 {
    font-size: 34px;
    line-height: 38px;
  }
}

html,
body {
  margin: 0px;
}
#crmWebToEntityForm.zcwf_lblLeft {
  width: 100%;
  padding: 25px;
  margin: 0 auto;
  box-sizing: border-box;
}
#crmWebToEntityForm.zcwf_lblLeft * {
  box-sizing: border-box;
}
#crmWebToEntityForm {
  text-align: left;
}
#crmWebToEntityForm * {
  direction: ltr;
}
.zcwf_lblLeft .zcwf_title {
  word-wrap: break-word;
  padding: 0px 6px 10px;
  font-weight: bold;
}
.zcwf_lblLeft.cpT_primaryBtn:hover {
  background: linear-gradient(#02acff 0, #006be4 100%) no-repeat padding-box !important;
  box-shadow: 0 -2px 0 0 #0159b9 inset !important;
  border: 0 !important;
  color: #fff !important;
  outline: 0 !important;
}
.zcwf_lblLeft .zcwf_col_fld input[type="text"],
input[type="password"],
.zcwf_lblLeft .zcwf_col_fld textarea {
  width: 60%;
  border: 1px solid #c0c6cc !important;
  resize: vertical;
  border-radius: 2px;
  float: left;
}
.zcwf_lblLeft .zcwf_col_lab {
  width: 30%;
  word-break: break-word;
  padding: 0px 6px 0px;
  margin-right: 10px;
  margin-top: 5px;
  float: left;
  min-height: 1px;
}
.zcwf_lblLeft .zcwf_col_fld {
  float: left;
  width: 68%;
  padding: 0px 6px 0px;
  position: relative;
  margin-top: 5px;
}
.zcwf_lblLeft .zcwf_privacy {
  padding: 6px;
}
.zcwf_lblLeft .wfrm_fld_dpNn {
  display: none;
}
.dIB {
  display: inline-block;
}
.zcwf_lblLeft .zcwf_col_fld_slt {
  width: 60%;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 4px;
  font-size: 12px;
  float: left;
  resize: vertical;
  padding: 2px 5px;
}
.zcwf_lblLeft .zcwf_row:after,
.zcwf_lblLeft .zcwf_col_fld:after {
  content: "";
  display: table;
  clear: both;
}
.zcwf_lblLeft .zcwf_col_help {
  float: left;
  margin-left: 7px;
  font-size: 12px;
  max-width: 35%;
  word-break: break-word;
}
.zcwf_lblLeft .zcwf_help_icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: inline-block;
  background: #fff;
  border: 1px solid #c0c6cc;
  color: #c1c1c1;
  text-align: center;
  font-size: 11px;
  line-height: 16px;
  font-weight: bold;
  border-radius: 50%;
}
.zcwf_lblLeft .zcwf_row {
  margin: 15px 0px;
}
.zcwf_lblLeft .formsubmit {
  margin-right: 5px;
  cursor: pointer;
  color: var(--baseColor);
  font-size: 12px;
}
.zcwf_lblLeft .zcwf_privacy_txt {
  width: 90%;
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-family: Arial;
  display: inline-block;
  vertical-align: top;
  color: var(--baseColor);
  padding-top: 2px;
  margin-left: 6px;
}
.zcwf_lblLeft .zcwf_button {
  font-size: 12px;
  color: var(--baseColor);
  border: 1px solid #c0c6cc;
  padding: 3px 9px;
  border-radius: 4px;
  cursor: pointer;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.zcwf_lblLeft .zcwf_tooltip_over {
  position: relative;
}
.zcwf_lblLeft .zcwf_tooltip_ctn {
  position: absolute;
  background: #dedede;
  padding: 3px 6px;
  top: 3px;
  border-radius: 4px;
  word-break: break-word;
  min-width: 100px;
  max-width: 150px;
  color: var(--baseColor);
  z-index: 100;
}
.zcwf_lblLeft .zcwf_ckbox {
  float: left;
}
.zcwf_lblLeft .zcwf_file {
  width: 55%;
  box-sizing: border-box;
  float: left;
}
.clearB:after {
  content: "";
  display: block;
  clear: both;
}

#products {
  padding: 70px 45px;
  background-color: #f0efec;
  text-align: center;
}

@media all and (max-width: 600px) {
  .zcwf_lblLeft .zcwf_col_lab,
  .zcwf_lblLeft .zcwf_col_fld {
    width: auto;
    float: none !important;
  }
  .zcwf_lblLeft .zcwf_col_help {
    width: 40%;
  }
}
.get-started .dropdown {
  position: absolute !important;
  padding: 20px 15px !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background: #fff;
  z-index: 99;
  opacity: 0;
  top: -500px;
  transition: 0.5 ease-in-out;
}
.get-started .dropdown ul {
  list-style: none !important;
  padding: 0;
}
.get-started .link {
  background: transparent !important;
  border: none !important;
  text-align: left;
  text-decoration: none;
  color: #000;
  width: inherit !important;
  height: inherit !important;
  padding: 10px 25px !important;

  display: inline-block;
}
.get-started:hover .dropdown {
  top: 60px;
  transition: 0.5 ease-in-out;
  opacity: 1;
}

/* responsive */
@media (max-width: 1303px) {
  footer .col-md-4 {
    width: 50%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  footer .col-md-3 {
    width: 50%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  footer .col-md-5 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  footer .right-part ul {
    padding: 0;
  }
}
.video-frame {
  height: inherit;
  margin: 0 auto;
  width: 720px;
}
.video-wrapper {
  text-align: center;
  margin: 100px 0 0;
}

@media (max-width: 1200px) {
  .banner-card p {
    font-size: 17px;
  }
  .contact .contact-image img {
    width: 60px;
    height: 60px;
  }
  .contact .contact-info {
    margin-top: -17px;
  }
  .contact .contact-info h5 {
    margin-bottom: -15px;
    font-size: 18px;
  }
  .contact .contact-info a {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .contact-form .single-banner-card {
    width: 80%;
  }
  .banner-card .single-banner-card {
    height: 160px;
    background-color: white;
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    bottom: 145px;
    margin-bottom: -145px;
  }
  .banner-card .col-md-3:nth-child(1) .single-banner-card:after {
    bottom: 75%;
    padding: 13px 13px 9px 12px;
    left: 25%;
  }
  .banner-card .col-md-3:nth-child(2) .single-banner-card:after {
    bottom: 75%;
    padding: 13px 13px 9px 12px;
    left: 25%;
  }
  .banner-card .col-md-3:nth-child(3) .single-banner-card:after {
    bottom: 75%;
    padding: 17px 16px 13px 16px;
    left: 25%;
  }
  .banner-card .col-md-3:nth-child(4) .single-banner-card:after {
    bottom: 75%;
    padding: 20px 11px 10px 11px;
    left: 25%;
  }

  .banner-card p {
    padding: 40px 10px;
    font-size: 15px;
  }
  .services h2 {
    font-size: 48px;
    line-height: 64px;
  }
  .contact {
    margin-bottom: -40px;
  }
  .contact .row .col-md-4 {
    width: 100%;
    display: flex;
    margin-bottom: 30px;
    padding-left: 34px;
  }
  .faq .antd-collapse {
    margin: 0px;
  }
}
@media (min-width: 769px) {
  .hide-in-large {
    display: none;
  }

  .banner .banner-content h1 {
    font-size: 30px;
  }
}

@media (max-width: 1206px) {
  /* .anchor-group{
    margin-top: -40px;
  } */
}

@media (min-width: 980px) {
  .anchor-group {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .anchor-group {
    display: flex;
    gap: 10px;
    justify-content: center;
  }

  .banner-content h1 {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .hide-in-small {
    display: none;
  }
  .sf-root img {
    width: 80px !important;
  }
  #products {
    padding: 70px 20px;
  }
  .pricing-table .item {
    width: 100%;
  }
  .full-modal .modal-content {
    height: 100%;
    width: 100%;
    border-radius: 0;
    margin: 0 auto;
    left: 0;
  }
  .banner .banner-content h1 {
    font-size: 30px;
    line-height: 40px;
    padding: 30px 20px;
  }
  .full-modal .btn {
    display: none;
  }

  .services .row:nth-child(even) .watch-video-btn {
    margin-left: 0px;
  }
  .full-modal .modal-content {
    height: 100% !important;
    width: 100% !important;
  }
  .full-modal .modal-dialog {
    width: 100%;
    margin: 20vh 0 !important;
  }

  .watch-video-btn {
    margin-bottom: 80px;
    text-align: center;
    justify-content: center;
    width: 100%;
  }

  .video-frame {
    margin: 0px auto;
    width: 90%;
    height: inherit;
  }
  .banner {
    height: inherit;
  }
  .video-wrapper {
    text-align: center;
    margin: 0px 0 0;
  }
  .service-info span {
    left: 65px;
    top: -40px;
    font-size: 70px;
  }
  .banner-card {
    margin-top: 60px;
  }
  .banner-card .row .col-md-3 {
    width: 50%;
  }
  .banner-card .single-banner-card {
    height: 160px;
    background-color: white;
    position: relative;
    border-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    bottom: 0 !important;
    margin-bottom: 80px !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  }
  .banner-card .col-md-3 .single-banner-card:after {
    left: 33% !important;
  }
  .banner-card .single-banner-card p {
    padding: 57px 10px;
  }
  .services {
    padding: 0px 10px;
  }
  .services h2 {
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    margin-bottom: 20px;
  }
  .services .row:nth-child(even) h2 {
    margin-left: 0 !important;
  }
  footer .row {
    flex-direction: column-reverse;
  }
  footer .col-md-4 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  footer .col-md-3 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  footer .right-part ul {
    padding: 0;
  }
}

@media (max-width: 576px) {
  .btn-started {
    display: none;
  }
  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .navbar .right-part {
    width: 30%;
  }
  .navbar .left-part {
    width: 70%;
  }
  img.baddge {
    width: 110px !important;
    top: 0;
  }
  .right-part span {
    display: none;
  }
  .arrow svg {
    display: none;
  }
  .banner-card .single-banner-card {
    height: 160px;
    background-color: white;
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    bottom: 145px;
    margin-bottom: -145px;
  }
  .services h2 {
    font-size: 38px;
    line-height: 44px;
    text-align: center;
  }
  .faq h1 {
    font-size: 27px;
  }
  .privacy-policy h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 49px;
    margin-bottom: 20px;
  }
  .terms-service h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 49px;
    margin-bottom: 20px;
  }
  .cookies-policy h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 49px;
    margin-bottom: 20px;
  }
  .services img {
    height: auto;
  }
}

@media (max-width: 480px) {
  .centered-modal .modal-dialog {
    position: fixed;
    bottom: 0;
  }
  .centered-modal .modal-header {
    border: none;
    padding: 10px 0 5px;
  }
  .centered-modal .modal-content {
    background: rgb(255 255 255 / 70%);
    backdrop-filter: saturate(500%) blur(200px);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 8px 0px;
    border: none;
  }
  .centered-modal .form-control {
    height: 55px;
    font-weight: 500;
    font-size: 16px;
    border-radius: 10px;
    color: #000;
    border: none;
    background: #ffffffbf;
  }
  .centered-modal button:hover,
  .centered-modal button:focus {
    background: #ed670e;
    color: #fff;
  }

  .centered-modal button {
    width: 200px;
    height: 55px;
    font-size: 18px;
    font-weight: 600;
    background: #ed670e;
    border: none;
    margin: auto;
  }
  footer {
    margin-bottom: 0 !important;
    padding-bottom: 100px !important;
  }
  .fixed-button {
    background: rgb(236 100 6) !important;
    color: rgb(255, 255, 255);
    position: fixed;
    left: 0;
    right: 0;
    text-decoration: none;
    height: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-weight: 500;
    font-size: 18px;
    z-index: 1;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    bottom: 20px;
    width: 200px;
    margin: 0 auto;
    text-align: center;
    border: none;
  }

  .fixed-button:hover {
    border: 2px solid #ec6408;
    color: #ec6408;
  }

  .centered-modal .modal-title {
    font-size: 20px;
    text-transform: capitalize;
    text-align: center;
    display: block;
    width: 100%;
    font-weight: 500;
    padding: 8px 0 0;
    color: #000000;
  }
  .contact .row .col-md-4 {
    margin-left: 0;
  }
  .contact {
    padding: 75px 20px;
  }
  .faq h1 {
    font-size: 24px;
  }
  .banner-card .row {
    justify-content: center;
  }
  .banner-card .row .col-md-3 {
    width: 95%;
  }
  .hide-lg {
    display: block;
    bottom: 0;
    z-index: 1;
    position: fixed;
    width: 100%;
  }
  .hide-lg a {
    padding: 16px 20px 32px 20px;
    text-transform: uppercase;
    border: 1px solid #fff;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    height: 46px;
    background: #ebb100;
    text-decoration: none;
    color: #000;
    width: 100%;
    display: block;
    text-align: center;
  }
  footer {
    margin-bottom: 49px;
  }
}

img.baddge {
  width: 200px;
  position: absolute;
  top: -40px;
}
.watch-video-btn {
  display: flex;
  align-items: center;
  color: #d75527;
  background: transparent !important;
  border: none !important;
  text-decoration: none;
}
.watch-video-btn h5 {
  margin: 0;
  margin-left: 8px;
}
.watch-video-btn img {
  width: 30px;
  height: 30px;
}

@media (max-width: 380px) {
  .contact .contact-image img {
    width: 46px;
    height: 46px;
  }
  .contact .contact-info h5 {
    margin-bottom: -21px;
    font-size: 15px;
  }
  .contact .contact-info a {
    font-size: 16px;
  }
  .services h2 {
    font-size: 34px;
    line-height: 38px;
  }
}

.full-modal {
  .modal-dialog {
    width: 100vw;
    margin: 50px;
  }
  .modal-content {
    height: 85vh;
    width: 93vw;
    border-radius: 0;
    margin: 0 auto;
    left: 0;
  }
  .btn {
    border: none;
    position: absolute;
    color: #000 !important;
    font-size: 36px;
    right: 0;
    top: -10px;
    z-index: 9999;
    background: transparent !important;
  }
}
.contact-form .zcwf_row {
  margin: 20px 0;
}
.contact-form form label {
  font-size: 13px;
  margin-bottom: 4px;
  font-weight: 400;
  color: #575757;
}
/* .contact-form form {
  background: #fff;
  padding: 30px;
  box-shadow: 0 0 30px 0 #0c213e30;
  border-radius: 10px;
} */
.contact-form input,
.contact-form textarea {
  width: 100%;
  background: #fff !important;
  border: 1px solid #c4c4c4;
  padding: 0 15px;
}
.contact-form .reset-btn {
  border: none;
  color: rgb(255 140 140);
  display: inline-block;
  width: inherit !important;
}
.contact-form .reset-btn:hover {
  color: rgb(181 20 20);
}
.contact-form #formsubmit {
  background: #54a937 !important;
  text-decoration: none;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0 20px;
  border-radius: 4px;
  font-size: 18px;
  color: #fff;
  border: none;
}

/* ================================================================================ */

/* ---------NewBannerCard-------- */
.new-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  /* margin-top: -120px; */
  padding: 5px 0;
}

@media (min-width: 769px) {
  .new-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    margin-top: -120px;
    padding: 5px 0;
  }
}
.recent-logo-ctn .single-image:nth-child(6),
.recent-logo-ctn .single-image:nth-child(5) {
  filter: invert(1);
}
.card {
  flex-grow: 1;
  width: 200px;
}
.new-card-container .card {
  padding: 50px;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 0px !important;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  border-bottom: 5px solid white;
}
.new-card-container .card:hover {
  border-bottom: 5px solid #ec6408;
}

.card p {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  background: white;
  color: #37394d;
}

.new-card-container .card .react-icons {
  font-size: 40px;
  color: #ec6408;
}

.anticon svg {
  color: #ec6408;
}

.ant-collapse-header {
  border: none;
}

/* =======faq.js========= */
.bg-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.icons-ctn {
  background-color: #ebc1a6;
  width: max-content;
  padding: 5px 5px;
  border-radius: 50%;
}

.small-icons {
  color: #ec6408;
}

.title-ctn {
  margin-top: 10px;
  min-height: 107px;
}

.title-ctn .sub-title {
  color: #838383;
  font-weight: 500;
  font-size: 13px;
  margin-top: 6px;
}

.sub-title2 {
  color: #838383;
  font-weight: 500;
  font-size: 13px;
  margin-top: -15px;
}

.title {
  font-size: 25px;
  font-weight: 800;
  letter-spacing: 1px;
  color: #212529;
}

.price-section {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.price-section .p1 {
  font-size: 50px;
  font-weight: 800;
  color: #ec6408;
}
.price-section .p2 {
  font-size: 20px;
}
.price-section .p3 {
  font-size: 17px;
  color: #212529;
}

.req-ctn {
  padding: 10px 0;
}

.outlined-btn {
  border: none;
  outline: 2px solid #ec6408;
  padding: 10px 30px;
  width: 80%;
  font-size: 16px;
  font-weight: 500;
  color: #ec6408;
}

.outlined-btn:hover {
  background-color: #ec6408;
  color: white;
}

.filled-btn {
  border: none;
  /* outline: 2px solid #ec6408; */
  padding: 10px 30px;
  width: 80%;
  font-size: 16px;
  font-weight: 500;
  background-color: #ec6408;
  color: white;
}

.plan-ctn {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  padding-top: 30px;
}

.plan-ctn label {
  text-align: left;
  color: #525252;
}

.img-icon {
  width: 30px;
  height: 28px;
}

.header-img-ctn {
}

.header-img {
  /* width: 60%; */
  width: 100%;
  height: 130px;
  object-fit: contain;
  mix-blend-mode: multiply;
}

.flex-justify-center {
  display: flex;
}

/* -------price-table --------- */

.table-ctn {
  overflow-x: auto;
}

.content .price-tag {
  font-size: 15px;
  margin: 0;
  margin-top: -30px;
  color: #525252;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td {
  text-align: left;
  padding: 0px 8px;
  color: #525252;
}

tr:nth-child(even) {
  background-color: #ebd3c46e;
}

.table-ctn {
  margin-top: 20px;
}

th:first-child {
  width: 600px; /* Adjust the width as needed */
  text-align: left;
}

th {
  text-align: left;
  font-weight: 800;
  color: #212529;
}

.th-styled {
  text-align: left;
  font-weight: 600;
  color: #212529;
  background: #ec6408;
  padding: 15px;
  color: white;
}

.tick-img {
  width: 25px;
  height: 40px;
}

.price-img {
  width: 350px;
  height: 120px;
  object-fit: contain;
  margin-top: -30px;
}
@media (max-width: 769px) {
  .price-img {
    width: 250px;
    height: 120px;
    object-fit: contain;
    margin-top: -30px;
  }
}

/* =========button-type========= */
.outlined-btn {
  border: none;
  outline: 2px solid #ec6408;
  padding: 10px 30px;
  width: 80%;
  font-size: 16px;
  font-weight: 500;
  color: #ec6408;
}

.header-outlined-btn {
  border: none;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background-color: #e6610b;
  border-radius: 1px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.mid-outlined-btn {
  border: none;
  background-color: #e6610b;
  color: white;
  font-size: 16px;
  font-weight: 500;
  padding: 0px 40px;
}

.submit-btn {
  background-color: #ec6408;
  border: none;
  color: white;
  padding: 8px;
  width: 100%;
  font-weight: 500;
}

/* ======new-contact========= */

.form-container {
  display: flex;
  justify-content: center;
  min-height: 500px;
  width: 70%;
  margin: auto;
}

.form-right {
  padding: 30px 40px;
  background-color: #f0efec;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-right .contact-div {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.form-right .contact-div .input-label {
  font-size: 15px;
  font-weight: 500;
}

.form-right .contact-div input {
  padding: 8px 0;
  padding-left: 10px;
  padding-right: 10px;
}

.contact-div .input-label .text-areas {
  border-radius: 8px;
}

.check-div {
  display: flex;
}

.check-div .check-input {
  width: 15px;
  height: 20px;
  margin-right: 10px;
}

.check-div .check-label {
  font-size: 13px;
}

.submit-div .submit-button {
  background-color: #ec6408;
  color: white;
  padding: 7px 0px;
  width: 100%;
  border: none;
}

.flex-input-group {
  display: flex;
  gap: 6px;
}

.width-50 {
  width: 50%;
}
.not-found{
  min-height: 75vh;
}

@media (width > 769px) {
  .hide-div-in-large {
    display: none;
  }

  .hide-div-in-small {
    display: flex;
    gap: 10px;
  }
}

@media (width < 768px) {
  .hide-div-in-small {
    display: none;
  }

  .hide-div-in-large {
    display: flex;
    gap: 5px;
  }
}

/* ---our-recent-client-logo------- */
.recent-client-div {
  border-top: 1px solid #ccdaf9;
}

.recent-logo-ctn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
}

.recent-client-div p {
  font-size: 20px;
  color: #181818;
  padding: 0 0;
}

.single-image {
  height: 70px;
  margin-bottom: 20px;
  max-width: 150px;
}

.single-image img {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
  backface-visibility: hidden;
  /* mix-blend-mode: multiply; */
  /* display: none; */
}
